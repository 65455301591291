import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface MenuProps {
  setOpen: (open: boolean) => void;
}

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.5em;
    margin: 15px 0;

    &:hover {
      color: #ffcccb;
    }
  }
`;

const Menu: React.FC<MenuProps> = ({ setOpen }) => {
  const handleLinkClick = () => {
    setOpen(false);
  };

  return (
    <MenuContainer>
      <NavLink onClick={handleLinkClick} to="/">Home</NavLink>
      <NavLink onClick={handleLinkClick} to="/about">About Us</NavLink>
      <NavLink onClick={handleLinkClick} to="/contact">Contact Us</NavLink>
    </MenuContainer>
  );
};

export default Menu;
