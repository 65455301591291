import React from 'react';
import styled from 'styled-components';

interface HamburgerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const StyledHamburger = styled.div<{ open: boolean }>`
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  z-index: 20;

  div {
    width: 100%;
    height: 3px;
    background-color: #fff;  // Set to white regardless of state
    transition: all 0.3s linear;
    transform-origin: 1px;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const Hamburger: React.FC<HamburgerProps> = ({ open, setOpen }) => {
  return (
    <StyledHamburger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledHamburger>
  );
};

export default Hamburger;
