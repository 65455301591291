import { PageWrapper, Title, Text } from '../components/styledComponents';
import styled from 'styled-components';
import facebook from '../assets/facebook.png';

const ContactInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
`;

const FacebookLink = styled.a`
    display: inline-flex;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
    gap: 10px;
`;

const FacebookLogo = styled.img`
    width: 24px;
    height: 24px;
`;

const ContactUs: React.FC = () => {
  return (
    <PageWrapper>
      <Title>Contact Us</Title>
      <ContactInfo>
        <Text>Email: <a href="mailto:info@jessiesgirlwv.com" style={{color: '#1ed760'}}>info@jessiesgirlwv.com</a></Text>
        <FacebookLink href="https://www.facebook.com/jessiesgirl80sband" target="_blank" rel="noopener noreferrer">
          <FacebookLogo src={facebook} alt="Facebook logo" />
          Follow us on Facebook
        </FacebookLink>
      </ContactInfo>
    </PageWrapper>
  );
}

export default ContactUs;
