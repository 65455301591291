import { PageWrapper, Title, Text, StyledImage } from '../components/styledComponents';

import jgLogo from '../assets/jglogo.jpg';

const Home: React.FC = () => {
  return (
    <PageWrapper>
      <Title>Jessie's Girl and The Walkmen 80s Retro Band</Title>
      <StyledImage src={jgLogo} alt="Jessie's Girl and The Walkmen" />
      <Text>Located in Morgantown, WV</Text>
    </PageWrapper>
  );
}

export default Home;
