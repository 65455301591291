import React, { useState } from "react";
import styled from "styled-components";
import Hamburger from "./Hamburger";
import Menu from "./Menu";
import { NavLink } from "react-router-dom";

const Nav = styled.nav`
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: #ff416c;
  color: #ffffff;
  padding: 0 15px;
  align-items: center;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

const BandName = styled.h1`
  font-size: 1.5em;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

const DesktopMenu = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileMenuContainer = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 65, 108, 0.95);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;

  @media (min-width: 769px) {
    display: none;
  }
`;

const NavBar: React.FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <Nav>
      <NavLink
        style={{ textDecoration: "none", cursor: "pointer" }}
        to="/"
      >
        <BandName>Jessie's Girl and The Walkmen</BandName>
      </NavLink>
      <DesktopMenu>
        <NavLink style={{ color: "#ffffff", textDecoration: "none" }} to="/">Home</NavLink>
        <NavLink style={{ color: "#ffffff", textDecoration: "none" }} to="/about">About Us</NavLink>
        <NavLink style={{ color: "#ffffff", textDecoration: "none" }} to="/contact">Contact Us</NavLink>
      </DesktopMenu>
      <Hamburger open={open} setOpen={setOpen} />
      <MobileMenuContainer open={open}>
        <Menu setOpen={setOpen} />
      </MobileMenuContainer>
    </Nav>
  );
};

export default NavBar;
