// Events.tsx

import React from 'react';
import styled from 'styled-components';
import {PageWrapper, Title} from '../components/styledComponents';

// TODO: Only show upcoming events
// and events for the past week
const shows = [
  { location: "Tropics Morgantown, WV", date: "June 30, 2023", time: "7:00 PM" },
  { location: "Sabraton Station Morgantown, WV", date: "July 29, 2023", time: "8:00 PM" },
  { location: "Lake House Morgantown, WV", date: "August 18, 2023", time: "8:00 PM" },
  { location: "Sabraton Station Morgantown, WV", date: "October 28, 2023", time: "8:00 PM" },
];

const Table = styled.table`
  width: 80%; // Adjust the width
  border-collapse: collapse;
  margin: 0 auto; // Center the table

  th, td {
    border-bottom: 1px solid #dddddd;
    padding: 15px;
    text-align: left;
  }

  th {
    background-color: #ff416c;
    color: #ffffff;
  }
`;

const Shows: React.FC = () => {
  return (
    <PageWrapper>
      <Title>Shows</Title>
      <Table>
        <thead>
          <tr>
            <th>Location</th>
            <th>Date</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {shows.map((show, index) => (
            <tr key={index}>
              <td>{show.location}</td>
              <td>{show.date}</td>
              <td>{show.time}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </PageWrapper>
  );
};

export default Shows;
