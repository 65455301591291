import styled from "styled-components";
import { PageWrapper, Title } from "../components/styledComponents";
import bandImage from "../assets/bandpic2024.jpg";

const AboutUs: React.FC = () => {
  return (
    <PageWrapper>
      <Title>About Us</Title>
      <ContentWithImage>
        <StyledImage src={bandImage} alt="Jessie's Girl and The Walkmen" />
        <ContentText>
          <p>
            We are Jessie's Girl and The Walkmen, an energetic band with a
            passion for the pulsating beats and captivating melodies of the 80s.
            Our journey began in 2015, when a group of friends came together
            over a shared love of music and nostalgia for the unique sounds of a
            bygone era.
          </p>
          <p>
            From the start, our aim was to transport audiences back in time, to
            an age of outrageous fashion, neon lights, and iconic tunes. And for
            five years, that's exactly what we did, delivering high-octane
            performances that left audiences begging for more.
          </p>
          <p>
            In 2020, life led us down separate paths, and we decided to take a
            hiatus. It was a time of growth, of change, and of quiet
            introspection. Yet, the call of the stage was never far from our
            minds.
          </p>
          <p>
            Fast forward to 2024, we find ourselves drawn back to the spotlight,
            our love for the 80s and the joy of performing for our incredible
            fans stronger than ever. We are thrilled to announce that Jessie's
            Girl and The Walkmen are back! We can't wait to rock the stage
            again, to bring back the powerful vibes of the 80s, and to share the
            magic of music with all of you.
          </p>
          <p>
            So here's to the past that has shaped us, the present that holds us,
            and the future that awaits us. Get ready, because Jessie's Girl and
            The Walkmen are here to take you on a spectacular musical journey
            you won't forget.
          </p>
        </ContentText>
      </ContentWithImage>
    </PageWrapper>
  );
};

const ContentWithImage = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ContentText = styled.div`
  width: 50%;
  padding: 2rem;
  color: #ffffff;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledImage = styled.img`
  width: 40%; // Adjust this to change the size of the image
  max-height: 70vh;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

export default AboutUs;
