import styled from "styled-components";

export const Nav = styled.nav`
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  display: flex;
  justify-content: space-around;
  padding: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
`;

export const PageWrapper = styled.div`
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  padding: 15px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const Title = styled.h1`
  color: #ffffff;
  font-size: 2.5rem;
  text-shadow: 3px 3px #ff416c;
  text-align: center;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Text = styled.p`
  color: #ffffff;
  font-size: 1.2rem;
  text-align: center;
  max-width: 800px;
  margin: auto;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const StyledLink = styled.p`
  cursor: pointer;
  &.navLink {
    color: #fff;
    text-decoration: none;
  }

  &.active {
    color: #ff416c;
  }

  margin-right: 10px;

  // on hover, change to underline and glow
  &:hover {
    text-decoration: underline;
    text-shadow: 0 0 10px #ffffff;
  }
`;

export const StyledImage = styled.img`
  max-width: 90%; // This makes sure the image is not too large
  max-height: 70vh; // This makes sure the image is not too tall
  display: block;
  margin: 0 auto; // This centers the image horizontally

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
